import { Link } from "react-router-dom";
import { AiFillGithub } from "react-icons/ai";
import { FaVimeoV } from "react-icons/fa";
import "./ProjectItem.css";
const ProjectItem = (props) => {
  const openLink = (link) => {
    window.open(link, "_blank");
  };
  return (
    <>
      <div className="card rising">
        <img
          className="card-img-top"
          src={props.image}
          alt="Card image cap"
          style={{
            borderRadius: "0.25rem 0.25rem 0px 0px",
            height: "250px",
            objectFit: "cover",
          }}
        />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
          <div className="d-flex align-items-center">
            <Link
              className="btn btn-sm btn-rounded btn-outline-primary mt-2"
              to={props.link}
            >
              Learn More
            </Link>
            {props.github && (
              <AiFillGithub
                size="30px"
                className="mt-2 ml-1"
                onClick={() => openLink(props.github)}
                style={{ cursor: "pointer" }}
              ></AiFillGithub>
            )}
            {props.vimeo && (
              <FaVimeoV
                size="30px"
                color="#0188CC"
                className="mt-2 ml-1"
                onClick={() => openLink(props.vimeo)}
                style={{ cursor: "pointer" }}
              ></FaVimeoV>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectItem;
