import React, { useEffect, useRef, useState } from "react";

import MedExpress from "../../assets/images/images/MedExpressLogo.webp";
import EcoSnap from "../../assets/images/images/ecoSNAPNew.webp";
import Promptu from "../../assets/images/images/promptu.webp";
import ChallengeForChange from "../../assets/images/images/ChallengeForChange.webp";
import Jackstones from "../../assets/images/images/jackstones.webp";
import WorldRefugeeDay from "../../assets/images/images/worldrefugeeday.webp";
import ChineseRadiothon from "../../assets/images/images/chineseradiothon.webp";
import BluetoothArduino from "../../assets/images/images/bluetootharduino.webp";
import OrdrUp from "../../assets/images/images/OrdrUp.webp";
import Cleanr from "../../assets/images/images/cleanr.webp";
import ProcrastinatorTerminator from "../../assets/images/images/ProcrastinatorTerminator.webp";
import ASLGuru from "../../assets/images/images/ASL-Guru.webp";
import SupplyMe from "../../assets/images/images/SupplyMe.webp";

import RBCIcon from "../../assets/images/RBC.webp";
import EnginehireIcon from "../../assets/images/enginehire-logo.webp";
import WorldVisionIcon from "../../assets/images/worldvision.webp";

import UofTIcon from "../../assets/images/UofTHacks.webp";
import CuHackingIcon from "../../assets/images/cuhacking.webp";
import ElevateIcon from "../../assets/images/Elevate.webp";
import TOHacks from "../../assets/images/TOHacks.webp";
import L3WescamIcon from "../../assets/images/L3Wescam.webp";
import MartinPortrait from "../../assets/images/images/Martin.webp";

import MartinPreview from "../../assets/images/Martin.jpg"
import TextLoop from "react-text-loop";
import ResumePDF from "../../assets/Resume.pdf";

import "./Home.css";
import axios from "axios";
import ExperienceItem from "../experience-item/ExperienceItem";
import ProjectItem from "../project-item/ProjectItem";

import desk from "../../assets/images/coffee.webp"
import mountain from "../../assets/images/mountains.webp"

const Home = (props) => {
  const [formName, setFormName] = useState("");
  const handleNameChange = (e) => setFormName(e.target.value);

  const [formEmail, setFormEmail] = useState("");
  const handleEmailChange = (e) => setFormEmail(e.target.value);

  const [formSubject, setFormSubject] = useState("");
  const handleSubjectChange = (e) => setFormSubject(e.target.value);

  const [formMessage, setFormMessage] = useState("");
  const handleMessageChange = (e) => setFormMessage(e.target.value);

  const [projects, setProjects] = useState([
    {
      link: "/ecosnap",
      image: EcoSnap,
      title: "ecoSnap",
      description:
        "An educational app that leverages AI to determine whether or not an item is recyclable.",
      github: "https://github.com/mendozanmartin/ecoSNAP",
    },
    {
      link: "/promptu",
      image: Promptu,
      title: "Promptu",
      description:
        "A social platform that raises awareness for various social issues.",
      github: "https://github.com/mendozanmartin/promptu",
    },
    {
      link: "/challengeforchange",
      image: ChallengeForChange,
      title: "Challenge for Change",
      description:
        "An instagram-like social app that allows you to challenge your peers.",
    },
    {
      link: "/ordrup",
      image: OrdrUp,
      title: "OrdrUp",
      description:
        "A scalable IoT point-of-sale for local businesses that simplifies the payment and dining experiences for customers.",
      github: "https://github.com/mendozanmartin/ordrup",
    },
    {
      link: "/jackstones",
      image: Jackstones,
      title: "Jackstones",
      description:
        "A Unity game that utilizes the Leap Motion Controller to drop jackstones into the water.",
      vimeo: "https://vimeo.com/347962005",
    },
    {
      link: "/arduinovacuum",
      image: BluetoothArduino,
      title: "Bluetooth Arduino Vaccuum",
      description:
        "An autonomous arduino vacuum bot with proximity detection and bluetooth connectivity",
      github: "https://github.com/mendozanmartin/Bluetooth-Arduino-Vacuum",
      vimeo: "https://vimeo.com/346768608",
    },
    {
      link: "/medexpress",
      image: MedExpress,
      title: "MedExpress",
      description:
        "A platform which allows patients to check into medical clinics, and obtain insights regarding their visits.",
      github: "https://github.com/mendozanmartin/MedExpress",
    },
    {
      link: "/refugeevrexperience",
      image: WorldRefugeeDay,
      title: "Refugee VR Experience",
      description:
        "A virtual reality experience that communicates the horrors of being caught in a war-torn country.",
      vimeo: "https://vimeo.com/346980435",
    },
    {
      link: "/radiothonbooth",
      image: ChineseRadiothon,
      title: "Radiothon Game",
      description:
        "An engaging augmented reality game that attracts people to a sponsorship booth.",
      vimeo: "https://vimeo.com/347959676",
    },
    {
      link: "/cleanr",
      image: Cleanr,
      title: "Cleanr IoT",
      description:
        "An IoT system that collects sanitary data on people who visit the washroom.",
      github: "https://github.com/mendozanmartin/Cleanr",
      vimeo: "https://vimeo.com/407736477",
    },
    {
      link: "/procrastinatorterminator",
      image: ProcrastinatorTerminator,
      title: "ProcrastinatorTerminator",
      description:
        "Chrome extension that allows you to keep track of how much time you're spending on websites.",
      github: "https://github.com/mendozanmartin/ProcrastinatorTerminator",
      vimeo: "https://vimeo.com/523237947",
    },
    {
      link: "/aslguru",
      github: "https://github.com/mendozanmartin/ASL-Guru",
      title: "ASL Guru",
      image: ASLGuru,
      vimeo: "https://vimeo.com/manage/videos/570050901",
      description:
        "Website that utilizes image recognition and artificial intelligence to teach you the ASL alphabet.",
    },
    // {
    //   link: "/smartwatertank",
    //   github: "https://github.com/mendozanmartin/Capstone-Project",
    //   title: "Capstone SMART Filter",
    //   vimeo: "https://vimeo.com/570049367",
    //   description:
    //     "An end-to-end water filtration system that monitors water level and quality using IoT technologies",
    // },
    {
      link: "/supplyme",
      github: "https://github.com/mendozanmartin/SupplyMe",
      title: "SupplyMe",
      image: SupplyMe,
      description:
        "A platform where corporations can donate much needed PPE to health care workers",
      vimeo: "https://vimeo.com/407639744",
    },
  ]);

  const workExperience = [
    {
      image: RBCIcon,
      text: [],
      position: "Site Reliability Engineer",
      company: "Royal Bank of Canada",
    },
    {
      image: RBCIcon,
      text: [
        "Architected, developed and deployed an AI insights system using Elasticsearch, OpenShift, Docker, MongoDB, Node.js, Angular, and Flask. Provisional patent has been filed for the AI algorithms and overall design of the system",
        "Presented bi-weekly demos to other RBC Amplify teams, VP Sponsors and Team Leads. Pitched at AmpExpo, an enterprise-wide event where RBC Amplify teams demonstrate their product to RBC stakeholders and executives",
        "Participated in a week long design-thinking program/hackathon to focus on developing high quality, innovative, and valuable products for consumers",
      ],
      position: "Amplify Software Developer Intern",
      company: "Royal Bank of Canada",
    },
    {
      image: EnginehireIcon,
      text: [
        "Built various customer and administrator features to improve operational efficiency of the client's business using Python Django, Vue.js and PostgreSQL",
        "Attended weekly client meetings to set expectations and manage deliverables according to feature requirements",
      ],
      position: "Full Stack Developer",
      company: "Enginehire",
    },
    {
      image: WorldVisionIcon,
      text: [
        "Lead end-to-end design and development of a real-time web gaming platform using Figma, Vue.js, Node.js and Socket.io",
        "Developed an augmented reality application which was used to acquire child sponsorships at World Vision's Chinese Radiothon (1000 Girls Campaign)",
        "Created a Virtual Reality Experience for World Refugee Day that was displayed at SoHo House and various internal events",
        "Lead end-to-end design and development of a social app that is currently being used for World Vision's Global 6K marathon",
      ],
      position: "Technology & Innovation Intern",
      company: "World Vision Canada",
    },
    {
      image: WorldVisionIcon,
      text: [
        "Gained experience designing UI/UX by building the team’s website using HTML, CSS and JavaScript",
        "Developed several engagement tools for Snapchat’s AR platform using Lens Studio's JavaScript API",
        "Familiarized with Unity's C# scripting API and created several augmented reality experiences through Google's ARCore and Vuforia",
        "Co-ordinated engagement methods and products with Channel Innovation team to optimize engagement delivery",
      ],
      position: "Technology R&D Intern",
      company: "World Vision Canada",
    },
  ];

  const hackathonExperience = [
    {
      company: "University of Toronto",
      position: "UofTHacks 2020",
      text: [
        "Developed a connected end-to-end solution for patient check-ins at a medical clinic",
      ],
      image: UofTIcon,
    },
    {
      company: "Carlton University",
      position: "cuHacking 2020 (Winner)",
      text: [
        "Developed an android application using Kotlin and Android Studio and Keras (Tensorflow) to detect recyclable items",
      ],
      image: CuHackingIcon,
    },
    {
      company: "Elevate",
      position: "Elevate Tech Jam 2019",
      image: ElevateIcon,
      text: [
        "Utilized NFC and IoT technologies to create a convenient and streamlined payment solution for restaurant businesses",
      ],
    },
    {
      company: "TOHacks",
      position: "TOHacks 2019",
      image: TOHacks,
      text: [
        "Collaborated with UX designers to create an interactive platform for raising awareness on various social issues",
      ],
    },
    {
      company: "L3 WESCAM",
      position: "HackWESCAM 2019",
      image: L3WescamIcon,
      text: [
        "Programmed a drone to maintain its position and control other drones using a custom C++ SDK and Open CV",
      ],
    },
  ];
  function openWebsite(website) {
    switch (website) {
      case "linkedIn":
        window.open(
          "https://www.linkedin.com/in/martin-mendoza/",
          "_blank" // <- This is what makes it open in a new window.
        );
        break;
      case "github":
        window.open("https://github.com/mendozanmartin", "_blank");
        break;
      case "email":
        window.open("mailto:mendozan@mcmaster.ca", "_blank");
        break;
      case "vimeo":
        window.open("https://vimeo.com/user100529261", "_blank");
        break;
      case "devpost":
        window.open("https://devpost.com/mendozanmartin", "_blank");
        break;
    }
  }

  function downloadResume() {
    window.open(ResumePDF, "_blank");
  }

  let submitForm = (e) => {
    e.preventDefault();
    const url =
      "https://us-central1-personal-website-ccd53.cloudfunctions.net/sendMail";

    const body = {
      name: formName,
      email: formEmail,
      subject: formSubject,
      message: formMessage,
    };

    axios
      .post(url, body)
      .then(() => {
        window.$("#modal").modal();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const nameTitle = useRef();
  const firstName = ["M", "A", "R", "T", "I", "N"];
  const lastName = ["M", "E", "N", "D", "O", "Z", "A"];

  useEffect(() => {
    console.log(nameTitle.current);
    typingSequence();
  }, [nameTitle]);

  const typingSequence = async () => {
    await delay(2000);
    for (const letter of firstName) {
      await delay(100);
      nameTitle.current.innerHTML += letter;
    }
    await delay(500);
    nameTitle.current.innerHTML += " ";
    for (const letter of lastName) {
      await delay(100);
      nameTitle.current.innerHTML += letter;
    }
    await delay(5000);
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  return (
    <div className="hero-background">
      <section style={{ height: "550px" }}>
        <div className="container landing-hero">
          <h1 class="martin-title">
            <span id="code">{"<"}</span>
            <span ref={nameTitle}></span>
            <span class={"blinking-cursor"}></span>
            <span id="code">{"/>"}</span>
          </h1>
          <h2 className="m-0 ml-2 p-0 tagline">
            Driving
            <TextLoop className="typing">
              <span>creativity </span>
              <span>innovation </span>
              <span>functionality </span>
            </TextLoop>
            into digital solutions.
          </h2>

          <ul className="socials">
            <li>
              <a
                className="icon-linkedin fs-40"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => openWebsite("linkedIn")}
              ></a>
            </li>
            <li>
              <a
                className="icon-github fs-40"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => openWebsite("github")}
              ></a>
            </li>
            <li>
              <a
                className="icon-vimeo fs-40"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => openWebsite("vimeo")}
              ></a>
            </li>
            <li>
              <a
                className="icon-devpost"
                style={{
                  color: "white",
                  cursor: "pointer",
                  height: "39px",
                  width: "39px",
                }}
                onClick={() => openWebsite("devpost")}
              ></a>
            </li>
            <li>
              <a
                className="icon-mail fs-40"
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => openWebsite("email")}
              ></a>
            </li>
          </ul>
        </div>
      </section>

      <section className="hero pb-0 pt-0" id="about">
        <div className="svg-container">
          <svg
            className="svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 40"
            preserveAspectRatio="none"
            fill="#f5f5f5"
          >
            <path d="">
              <animate
                attributeName="d"
                begin="0s"
                dur="5s"
                repeatCount="indefinite"
                values="
              M0,0 C200,7.11236625e-15 200,40 400,40 C600,40 800,0 1000,0 L1000,50 L0,50 L0,0 Z;
              M0,40 C200,40 400,0 600,0 C800,0 800,40 1000,40 L1000,50 L0,50 L0,40 Z;
              M0,30 C200,30 200,0 400,0 C600,0 800,40 1000,40 L1000,50 L0,50 L0,30 Z;
              M0,0 C200,7.11236625e-15 200,40 400,40 C600,40 800,0 1000,0 L1000,50 L0,50 L0,0 Z;"
              ></animate>
            </path>
          </svg>
        </div>
        <div
          className="bg-light"
          style={{ paddingBottom: "100px", paddingTop: "100px" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h1 className="text-decorated">
                  Here's a little bit <b>about me</b>
                </h1>
                <div className="text-center mt-4">
                  <img
                    src={MartinPortrait}
                    alt="Martin"
                    className="MartinPhoto image-cropper mb-5"
                  />
                </div>

                <p className="text-black fs-20">
                  Hi, I'm Martin, a Toronto-based developer with a deep passion
                  for developing high quality, customer-centric, and innovative
                  products. I'm a recent graduate from the Bachelor of
                  Technology program at McMaster University and I'm currently
                  working as a Site Reliability Engineer for RBC. Apart from my
                  developer career, I enjoy my leisure time playing instruments,
                  singing, watching Netflix, and also playing lots of video
                  games. If you're interested in connecting with me (work
                  related or not), feel free to reach out!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-0 mt-0 mb-5 parallax-mountains" id="resume">

        <div
          className="tab-pane show active container"
          id="demo-2-4"
          role="tabpanel"
          aria-labelledby="demo-2-4"
        >

          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">

              <h1 className="text-center text-white mb-4 experience-section">
                Experience
              </h1>
              {workExperience.map((item, i) => (
                <ExperienceItem
                  key={i}
                  image={item.image}
                  text={item.text}
                  position={item.position}
                  company={item.company}
                ></ExperienceItem>
              ))}
              <h1 className="text-center text-white mb-4">Hackathons</h1>
              {hackathonExperience.map((item, i) => (
                <ExperienceItem
                  key={i}
                  image={item.image}
                  text={item.text}
                  position={item.position}
                  company={item.company}
                ></ExperienceItem>
              ))}
              <div className="text-center">
                <h2 className="text-center text-white">
                  Download a PDF of my Resume below!
                </h2>
                <button
                  onClick={downloadResume}
                  className="btn text-white mt-3"
                  style={{ backgroundColor: "#6d9eeb", outline: "0" }}
                >
                  Download Resume
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="project-gallery section-decorated-bottom bg-light">
        <div className="container-fluid" id="projects">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h1 className="text-decorated projects-header">
                Some of my <b>projects</b>
              </h1>
            </div>
          </div>

          <div class="container">
            <div className="row">
              <div className="col">
                <ul className="masonry row gutter-1 project-cards">
                  {projects.map((item, i) => (
                    <li key={i} className={"col-md-6 col-lg-4 card-cell "}>
                      <ProjectItem
                        vimeo={item.vimeo}
                        github={item.github}
                        item={i}
                        link={item.link}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                      ></ProjectItem>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax-desk" id="contact">
        {/* <div className="image image-overlay image-scrolling" style={{ backgroundImage: `url(${desk})` }}
          data--100-bottom-top="transform: translateY(0px);" data-top-bottom="transform: translateY(300px);">
        </div> */}

        <div
          className="tab-pane show active container"
          id="demo-2-4"
          role="tabpanel"
          aria-labelledby="demo-2-4"
        >
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <h1 className="text-center text-light contact-header">
                Hit me up!
              </h1>
              <div className="row">
                <div className="col">
                  <h5 className="mb-3 fs-20 font-weight-normal text-center text-light">
                    Reach out if you'd like to learn more about me, I'd love to
                    hear from you!
                  </h5>
                  <form onSubmit={submitForm}>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="name" className="text-light">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            aria-describedby="name"
                            placeholder="John Doe"
                            name="name"
                            value={formName}
                            onChange={handleNameChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="email" className="text-light">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="email"
                            placeholder="johndoe@gmail.com"
                            name="email"
                            value={formEmail}
                            onChange={handleEmailChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="subject" className="text-light">
                            Subject Line
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            aria-describedby="subject"
                            placeholder="I've got a job for you!"
                            name="subject"
                            value={formSubject}
                            onChange={handleSubjectChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="text-light"
                          >
                            Message
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder="I have a potential opening for you as a tech lead for Google! Let me know if you're interested. (This is just a joke)"
                            rows="3"
                            name="message"
                            value={formMessage}
                            onChange={handleMessageChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="form-row mt-1 align-items-center">
                      <div className="col-3">
                        <button
                          // onClick={submitForm}
                          type="submit"
                          className="btn text-light"
                          style={{ backgroundColor: "#6d9eeb", outline: "0" }}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* <!-- Launch Modal --> */}
                  <div
                    className="modal fade"
                    id="modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header justify-content-end">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true" className="icon-x"></span>
                          </button>
                        </div>
                        <div className="modal-body text-center">
                          <h3>Message sent!</h3>
                          <p>
                            Thank you for reaching out! I will get back to you
                            as soon as possible
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            data-dismiss="modal"
                            style={{
                              backgroundColor: "#6d9eeb",
                              outline: "none",
                            }}
                          >
                            Awesome!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Modal End --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
};

export default Home;
