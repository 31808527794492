import React, { useEffect } from "react";
import ASLGuruImage from "../../../assets/images/images/ASL-Guru.webp";

function ASLGuru() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <section className="hero hero-with-header">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-between">
              <h1 className="text-decorated">
                <b>ASLGuru</b>
              </h1>
            </div>
            <p className="fs-20">
              ASLGuru is a gamified website that uses image recognition to
              educate people about the ASL alphabet. Utilizing a live camera
              feed, ASLGuru will be able to determine which ASL letter a user is
              demonstrating.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={ASLGuruImage} alt="" width="100%" />
            <h4 className="mt-4">The User Experience</h4>
            <p className="text-black ps-20">
              Upon visiting the website, the user will be prompted to allow
              their browser to access their camera. A live camera feed will
              begin and the user will be able to initiate the game by pressing
              the 'Start' button. Every few seconds, a letter will appear in the
              box to the left and the user will have to perform the hand sign at
              the top right corner of their camera feed. When an image is
              captured, the user will be informed right away whether they
              created the correct hand sign and will be awarded accordingly.
            </p>
            <h4 className="mt-4">Next Steps</h4>
            <p className="text-black fs-20">
              The Kaggle dataset used in this project was taken using a solid
              background with the same hand across all images. As a result, the
              data was not diversified which led to issues classifying the
              correct hand sign. The next step for this project would be to
              generate more images with various environments (different hands,
              background, lighting) to develop a diversified dataset for release
              into the public domain. Another enhancement we hope to implement
              is to develop more game modes. At the moment, there is only a test
              mode but we hope to integrate an actual teaching mode where we
              show picture examples of ASL letters for users to match Another
              mode we would like to integrate is forming full words using the
              letters you form with your hands.
            </p>
            <h4 className="mt-4">Technologies used</h4>
            <ul className="mt-1">
              <li className="fs-20">Keras</li>
              <li className="fs-20">Plaidml GPU Booster</li>
              <li className="fs-20">Jupyter Notebooks</li>
              <li className="fs-20">HTML, CSS, JavaScript</li>
              <li className="fs-20">Python Flask</li>
              <li className="fs-20">Google Cloud App Engine</li>
              <li className="fs-20">Google Cloud Storage</li>
            </ul>
            <h4 className="mt-4">View a demo</h4>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src="https://player.vimeo.com/video/570050901"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ASLGuru;
