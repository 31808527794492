import React, { createRef, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { TerminalContextProvider } from "react-terminal";
import { CSSTransition } from "react-transition-group";

import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import ArduinoVacuum from "./components/projects/arduinovacuum/ArduinoVacuum";
import ASLGuru from "./components/projects/aslguru/ASLGuru";
import ChallengeForChange from "./components/projects/challengeforchange/ChallengeForChange";
import Cleanr from "./components/projects/cleanr/Cleanr";
import EcoSnap from "./components/projects/ecosnap/EcoSnap";
import JackStones from "./components/projects/jackstones/Jackstones";
import MedExpress from "./components/projects/medexpress/MedExpress";
import OrdrUp from "./components/projects/ordrup/Ordrup";
import ProcrastinatorTerminator from "./components/projects/procrastinatorterminator/ProcrastinatorTerminator";
import Promptu from "./components/projects/promptu/Promptu";
import SmartWaterTank from "./components/projects/smartwatertank/SmartWaterTank";
import SupplyMe from "./components/projects/supplyme/SupplyMe";

import RadiothonBooth from "./components/projects/radiothonbooth/RadiothonBooth";
import RefugeeVRExperience from "./components/projects/refugeevrexperience/RefugeeVRExperience";
import TerminalComponent from "./components/terminal/TerminalComponent";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/arduinovacuum", name: "Arduino Vacuum", Component: ArduinoVacuum },
  {
    path: "/challengeforchange",
    name: "Challenge For Change",
    Component: ChallengeForChange,
  },
  { path: "/ecosnap", name: "EcoSnap", Component: EcoSnap },
  { path: "/jackstones", name: "Jackstones", Component: JackStones },
  { path: "/medexpress", name: "Medexpress", Component: MedExpress },
  { path: "/ordrup", name: "Ordrup", Component: OrdrUp },
  { path: "/promptu", name: "Promptu", Component: Promptu },
  { path: "/cleanr", name: "Cleanr", Component: Cleanr },
  {
    path: "/procrastinatorterminator",
    name: "ProcrastinatorTerminator",
    Component: ProcrastinatorTerminator,
  },
  { path: "/aslguru", name: "ASLGuru", Component: ASLGuru },
  {
    path: "/smartwatertank",
    name: "SmartWaterTank",
    Component: SmartWaterTank,
  },
  { path: "/supplyme", name: "SupplyMe", Component: SupplyMe },

  {
    path: "/radiothonbooth",
    name: "RadiothonBooth",
    Component: RadiothonBooth,
  },
  {
    path: "/refugeevrexperience",
    name: "RefugeeVRExperience",
    Component: RefugeeVRExperience,
  },
];

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const vendorScript = document.createElement("script");
    const appScript = document.createElement("script");

    vendorScript.src = "../../js/vendor.js";
    vendorScript.async = true;
    document.body.appendChild(vendorScript);

    vendorScript.onload = () => {
      appScript.src = "../../js/app.js";
      appScript.async = true;
      document.body.appendChild(appScript);
    };
  });

  return (
    <main>
      <TerminalContextProvider>
        <Router>
          <Header></Header>
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div>
                    <Component />
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
          {/* <div className="sticky terminal" ref={terminal}>
            <TerminalComponent />
          </div> */}
          <div>
            {/* ref={footer} */}
            <Footer />
          </div>
        </Router>
      </TerminalContextProvider>
    </main>
  );
};

export default App;
