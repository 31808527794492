import React, { useEffect } from "react";
import ProcrastinatorTerminatorImage from "../../../assets/images/images/ProcrastinatorTerminator.webp";

function ProcrastinatorTerminator() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <section className="hero hero-with-header">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-between">
              <h1 className="text-decorated">
                <b>ProcrastinatorTerminator</b>
              </h1>
            </div>
            <p className="fs-20">
              ProcrastinatorTerminator is a Chrome extension keeps track of how
              much time is spent on different selected webpages. Each time the
              extension is clicked on in Chrome, it will update from the last
              time the popup was viewed. The popup offers 3 ways to view the
              data: pie chart, bar graph, and in a table. On each new day, the
              user has the option of clearing the data from the previous day.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={ProcrastinatorTerminatorImage} alt="" width="100%" />
            <h4 className="mt-4">Inspiration</h4>
            <p className="fs-20 text-black">
              {" "}
              COVID-19 has infiltrated all of our lives, making it more
              difficult to get things done in the comfort of our own homes. Most
              of us are glued to our laptops and computers for a majority of the
              day, but between Zoom meetings and classes, we're likely wasting
              time on social media sites. The inspiration for this Google Chrome
              extension came from the fact that it's easy to lose track of how
              much time we're wasting when we're on our computers all day.
              Seeing this information in the form of a graph will help to snap
              us back to reality. We also believe that time spent on social
              media plays a huge role in health, which is a conversation that is
              more frequently brought up now that we're at home.
            </p>
            <h4 className="mt-4">Next Steps</h4>
            <p className="fs-20 text-black">
              The next step for this project is to add a timer so that we're
              able to restrict our time on each website. Once a timer and set
              and surpassed, a popup message will notify the user. Even after
              COVID-19 is behind us, we think that most people will opt to hold
              meetings/classes at home, so we're optimistic that this extension
              will help a lot of people to stay productive in the future.
            </p>
            <h4 className="mt-4">Technologies used</h4>
            <ul className="mt-1">
              <li className="fs-20">HTML, CSS JavaScript</li>
              <li className="fs-20">BootStrap, Chart.js</li>
              <li className="fs-20">Chrome Extension Dev Tools</li>
            </ul>
            <h4 className="mt-4">View a demo</h4>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src="https://player.vimeo.com/video/523237947"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProcrastinatorTerminator;
