import React, { useEffect, useRef, useState } from "react";
import darkIcon from "../../assets/images/Martin-Logo-Dark.webp";
import lightIcon from "../../assets/images/Martin-Logo-Light.webp";
import "./Header.css";
import { Link } from "react-scroll";
import { Link as ReactLink, useHistory } from "react-router-dom";
const Header = () => {
  const location = window.location.href.split("/");
  let lightTheme = true;
  location[3] === "" ? (lightTheme = true) : (lightTheme = false);
  const [lightHeader, setLightHeader] = useState(lightTheme);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [menuTheme, setMenuTheme] = useState(false);
  const history = useHistory();
  const checkbox = useRef();
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return history.listen((path) => {
      setHeaderTheme(path.pathname);
    });
  });

  const setHeaderTheme = (path) => {
    if (path == "/") {
      setLightHeader(true);
    } else {
      setLightHeader(false);
    }
  };

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setStickyHeader(true);
    } else {
      setStickyHeader(false);
    }
  };
  const onHamburgerClick = () => {
    const status = checkbox.current.checked;
    if (status) {
      setMenuTheme(true);
    }
  };

  const closeHamburger = () => {
    checkbox.current.checked = false;
  };
  return (
    <div
      className={
        (lightHeader ? "transparent-navigation-bar" : "dark-navigation-bar") +
        (stickyHeader ? " sticky-navigation-bar" : "")
      }
    >
      <div className="container">
        <nav className="navbar navbar-expand-sm">
          <a href="/">
            <img
              className="navbar-logo navbar-logo-light"
              src={stickyHeader || !lightHeader ? darkIcon : lightIcon}
              alt="Logo"
            />
          </a>
          <div id="menuToggle">
            <input
              type="checkbox"
              id="hamburger"
              onChange={onHamburgerClick}
              ref={checkbox}
            />
            <span
              className={
                stickyHeader || !lightHeader
                  ? "background-black"
                  : "background-white"
              }
            ></span>
            <span
              className={
                stickyHeader || !lightHeader
                  ? "background-black"
                  : "background-white"
              }
            ></span>
            <span
              className={
                stickyHeader || !lightHeader
                  ? "background-black"
                  : "background-white"
              }
            ></span>
            <ul className="navbar-nav align-items-center" id="menu">
              <li className="nav-item">
                <Link
                  to="about"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  className="nav-link"
                  onClick={closeHamburger}
                >
                  <ReactLink
                    to="/"
                    className={
                      (stickyHeader || !lightHeader
                        ? "text-black"
                        : "text-white") + (menuTheme ? " menu-theme" : "")
                    }
                  >
                    ABOUT
                  </ReactLink>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="resume"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  className="nav-link"
                  onClick={closeHamburger}
                >
                  <ReactLink
                    to="/"
                    className={
                      (stickyHeader || !lightHeader
                        ? "text-black"
                        : "text-white") + (menuTheme ? " menu-theme" : "")
                    }
                  >
                    EXPERIENCE
                  </ReactLink>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="projects"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  className="nav-link"
                >
                  <ReactLink
                    to="/"
                    onClick={closeHamburger}
                    className={
                      (stickyHeader || !lightHeader
                        ? "text-black"
                        : "text-white") + (menuTheme ? " menu-theme" : "")
                    }
                  >
                    PROJECTS
                  </ReactLink>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="contact"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  className="nav-link"
                >
                  <ReactLink
                    to="/"
                    onClick={closeHamburger}
                    className={
                      (stickyHeader || !lightHeader
                        ? "text-black"
                        : "text-white") + (menuTheme ? " menu-theme" : "")
                    }
                  >
                    CONTACT
                  </ReactLink>
                </Link>{" "}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
