import React, { useEffect } from "react";
import SupplyMeImage from "../../../assets/images/images/SupplyMe.webp";

function SupplyMe() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <section className="hero hero-with-header">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-between">
              <h1 className="text-decorated">
                <b>SupplyMe</b>
              </h1>
            </div>
            <p className="fs-20">
              SupplyMe is a platform that connects corporations with healthcare
              facilities that are in need of PPE. When users log in, they have
              an option of listing as a supplier or a receiver. Receivers are
              then prompted to fill out a form, indicating the resources they
              require, including their location and contact information.
              Suppliers would then be able to see this information along with a
              heat map of covid cases to make an informed decision.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={SupplyMeImage} alt="" width="100%" />
            <h4 className="mt-4">Technologies used</h4>
            <ul className="mt-1">
              <li className="fs-20">Mapbox API</li>
              <li className="fs-20">Angular</li>
              <li className="fs-20">Bootstrap GO Theme</li>
              <li className="fs-20">Google Firebase </li>
            </ul>
            <h4 className="mt-4">View a demo</h4>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src="https://player.vimeo.com/video/407639744"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SupplyMe;
