import React, { useEffect } from "react";
import CleanrImage from "../../../assets/images/images/cleanr.webp";

function Cleanr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <section className="hero hero-with-header">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="d-flex justify-content-between">
              <h1 className="text-decorated">
                <b>Cleanr</b>
              </h1>
            </div>
            <p className="fs-20">
              Our project, Cleanr, will help evaluate how many people wash their
              hands after using the bathroom, without any invasive monitoring
              systems, such as cameras. When the person leaves the washroom, the
              sprayer automatically sprays an anti-bacterial spray to disinfect
              the area.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <img src={CleanrImage} alt="" width="100%" />
            <h4 className="mt-4">Summary</h4>

            <p className="fs-20 text-black">
              Personal hygiene is an important element of daily life and is of
              great importance in keeping our communities clean. This is
              especially true in pandemic breakouts like COVID-19. Handwashing,
              in particular, is an excellent way to maintain hygiene and prevent
              the spread of disease. Not all individuals wash their hands when
              using the washroom, however. Our project, Cleanr, will help
              evaluate how many people wash their hands after using the
              bathroom, without any invasive monitoring systems, such as
              cameras. When the person leaves the washroom, the sprayer
              automatically sprays an anti-bacterial spray to disinfect the
              area.
            </p>
            <p className="fs-20 text-black">
              The system operates by using a button sensor integrated into a
              sink tap, and a proximity sensor within a bathroom stall. The
              Cleanr system will monitor when a user is in the bathroom stall,
              and whether or not the sink tap was used. By logging this data
              with timestamps, the data can be analyzed to see if users are
              washing their hands after utilizing the bathroom. This system only
              works with a single-person washroom.
            </p>
            <h4 className="mt-4">Technologies used</h4>
            <ul className="mt-1">
              <li className="fs-20">Arduino</li>
              <li className="fs-20">Raspberry Pi</li>
              <li className="fs-20">Node.js</li>
              <li className="fs-20">HiveMQ</li>
              <li className="fs-20">Google Sheets</li>
              <li className="fs-20">Angular</li>
            </ul>
            <h4 className="mt-4">View a demo</h4>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src="https://player.vimeo.com/video/407736477"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cleanr;
